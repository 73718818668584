


























































































































































import { SearchBuilder } from "@/builder";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectProductCategory from "@/components/custom/select/SelectProductCategory.vue";
import SelectUnitCode from "@/components/custom/select/SelectUnitCode.vue";
import SelectWorkOrderHeader from "@/components/WorkOrder/SelectWorkOrderHeader.vue";
import {
  APagination,
  useBlob,
  useContactData,
  useDate,
  usePrint,
  useWorkOrder,
} from "@/hooks";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE, PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  DATE_TIME_HOURS_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { BranchWarehouseResponseDto } from "@/models/interface/branch";
import { ListContactDataDTO } from "@/models/interface/ContactDataDto.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { ProductCategoryResponseDto } from "@/models/interface/product-category";
import {
  WorkOrderListResponseDto,
  WorkOrderResponseDto,
} from "@/models/interface/work-order";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import Vue from "vue";

type Form = {
  unitCode: string;
  date: null | Moment[];
  woNumber: string;
  customer: string;
  branch: string;
  partCategory: string;
};

export default Vue.extend({
  name: "WorkOrderIndexPage",
  components: {
    SelectWorkOrderHeader,
    SelectUnitCode,
    SelectCustomer,
    SelectBranch,
    SelectProductCategory,
  },
  mixins: [],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      PAGE_SIZE_OPTIONS,
      formModel: this.$refs.formModel as FormModel,
      paramsHeader: {
        company: this.$store.state.authStore.authData.companyName,
        branchName: "ALL",
        woNumber: "ALL",
        dateFrom: "ALL",
        dateTo: "ALL",
        customerName: "ALL",
        unitCode: "ALL",
        partCategory: "ALL",
        reset() {
          this.branchName = "ALL";
          this.woNumber = "ALL";
          this.dateFrom = "ALL";
          this.dateTo = "ALL";
          this.customerName = "ALL";
          this.unitCode = "ALL";
          this.partCategory = "ALL";
        },
      },
      loading: {
        find: false,
        print: false,
        download: false,
      },
      pagination: {
        page: 1,
        limit: 10,
      },
      dataSource: {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalPages: 0,
      } as Pagination<WorkOrderListResponseDto>,
      form: {
        unitCode: "",
        date: null as null | Moment[],
        woNumber: "",
        customer: "",
        branch: "",
        partCategory: "",
      } as Form,
      columns: [
        {
          title: this.$t("lbl_work_order_number"),
          dataIndex: "workOrderNumber",
          key: "workOrderNumber",
          width: 200,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "workOrderDate",
          key: "workOrderDate",
          width: 125,
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: 100,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          //   width: 250,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: 150,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_number"),
          dataIndex: "productCode",
          key: "productCode",
          //   width: 250,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_name"),
          dataIndex: "productName",
          key: "productName",
          //   width: 250,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_category"),
          dataIndex: "productCategory",
          key: "productCategory",
          //   width: 250,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_qty"),
          dataIndex: "productQty",
          key: "productQty",
          width: 100,
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("lbl_created_by"),
          dataIndex: "createdBy",
          key: "createdBy",
          //   width: 150,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_action"),
          key: "action",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    this.formModel = this.$refs.formModel as FormModel;
  },
  methods: {
    moment,
    buildParams() {
      const { flatContactName } = useContactData();
      const headers = [
        this.paramsHeader.company,
        this.paramsHeader.branchName,
        this.paramsHeader.woNumber,
        this.paramsHeader.dateFrom,
        this.paramsHeader.dateTo,
        flatContactName(this.paramsHeader.customerName),
        this.paramsHeader.unitCode,
        this.paramsHeader.partCategory,
      ];
      return headers.join(",");
    },
    async handlePrint() {
      const { printList } = useWorkOrder();
      const { print } = usePrint();
      const { toObjectUrl } = useBlob();
      const params = this.buildQuery(this.form);
      params.params = this.buildParams();
      try {
        this.loading.print = true;
        const res = await printList(params);
        print(toObjectUrl(res));
      } finally {
        this.loading.print = false;
      }
    },
    handleSubmit() {
      this.fetchData(this.buildQuery(this.form));
    },
    buildQuery(form: Form): RequestQueryParamsModel {
      const { findByWoList } = useWorkOrder();
      const params = new RequestQueryParams();
      params.limit = this.pagination.limit;
      params.page = this.pagination.page - ONE;
      params.sorts = "workOrderDate:desc";
      params.search = findByWoList({
        assetId: form.unitCode,
        branchId: form.branch,
        customerId: form.customer,
        productCategoryId: form.partCategory,
        workOrderDate: form.date ?? [],
        workOrderId: form.woNumber,
      }).join(new SearchBuilder().AND);

      return params;
    },
    async fetchData(params: RequestQueryParamsModel) {
      const { findAll } = useWorkOrder();
      try {
        this.loading.find = true;
        this.dataSource = await findAll(params);
      } finally {
        this.loading.find = false;
      }
    },
    onTableChange(pagination: APagination): void {
      const { current, pageSize } = pagination;
      this.pagination.page = current;

      if (pageSize !== this.pagination.limit) {
        this.pagination.page = FIRST_PAGE;
      }

      this.pagination.limit = pageSize;

      const params = this.buildQuery(this.form);
      this.fetchData(params);
    },
    handleReset(): void {
      this.formModel.resetFields();
      this.form.date = [];
      this.paramsHeader.reset();
    },
    onChangeDate(value: Moment[] | null) {
      const { toStartDay, toEndDay } = useDate();
      this.paramsHeader.dateFrom = "ALL";
      this.paramsHeader.dateTo = "ALL";
      if (value && value.length) {
        const [start, end] = value;
        this.paramsHeader.dateFrom = toStartDay(start).format(
          DATE_TIME_HOURS_DEFAULT_FORMAT
        );
        this.paramsHeader.dateTo = toEndDay(end).format(
          DATE_TIME_HOURS_DEFAULT_FORMAT
        );
      }
    },
    onChangeBranch(opt: Option<BranchWarehouseResponseDto> | undefined) {
      this.paramsHeader.branchName = opt?.meta?.name ?? "ALL";
    },
    onChangeWo(opt?: Option<WorkOrderResponseDto>) {
      this.paramsHeader.woNumber = opt?.meta?.documentNumber ?? "ALL";
    },
    onChangeCustomer(opt?: Option<ListContactDataDTO>) {
      this.paramsHeader.customerName = opt?.meta?.fullName ?? "ALL";
    },
    onChangeUnit(opt: Option<AssetResponseDto> | undefined) {
      this.paramsHeader.unitCode = opt?.meta?.unitCode ?? "ALL";
    },
    onChangePartCategory(opt: Option<ProductCategoryResponseDto> | undefined) {
      this.paramsHeader.partCategory = opt?.meta?.name ?? "ALL";
    },
    async handleDownload() {
      const { toDownload } = useBlob();
      const { downloadList } = useWorkOrder();
      const params = this.buildQuery(this.form);
      params.params = this.buildParams();
      try {
        this.loading.download = true;
        const res = await downloadList(params);
        toDownload(res, "work-order-list.xlsx");
      } finally {
        this.loading.download = false;
      }
    },
  },
});
